import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import { roomSaleSearchApi } from "../api/api";
import { Button, ConfigProvider, DatePicker, Table } from "antd";
import { disabledDate, disabledTime, formatNumber } from "../util";

export const CheckAdmissionHistory = () => {
  // API 테스트
  const [checkInList, setCheckInList] = useState([]);
  const [columns, setColumns] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15 });

  const [startDateTime, setStartDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 00:00:00"));
  const [endDateTime, setEndDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"));
  // const [startDateTime, setStartDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"));

  const [totalSummary, setTotalSummary] = useState({
    totalCount : 0,
    totalPersonCount : 0,
    totalRoomFee : 0,
    totalFrontPay : 0,
    totalOta : 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomSaleSearchApi(startDateTime.valueOf(), endDateTime.valueOf());
        //const res = await roomSaleSearchApi(startDateTime.valueOf());
        console.log(res);

        const resArray = Object.entries(res.data);

        let tempTotalSummary = {
          totalCount : 0,
          totalPersonCount : 0,
          totalRoomFee : 0,
          totalFrontPay : 0,
          totalOta : 0
        };

        const saleArray = resArray.map(([key, value]) => {
          value.key = key;
          value.check_in_time = dayjs(value.check_in_time).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          value.check_out_time = dayjs(value.check_out_time).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          value.is_kiosk_check_in = value.is_kiosk_check_in ? "예" : "";
          value.front_pay = Number(value.account_pay) + Number(value.card_pay) + Number(value.cash_pay);

          tempTotalSummary = {
            totalCount : Number(tempTotalSummary.totalCount) + 1,
            totalPersonCount : Number(tempTotalSummary.totalPersonCount) + Number(value.person_count),
            totalRoomFee : Number(tempTotalSummary.totalRoomFee) + Number(value.room_fee),
            totalFrontPay : Number(tempTotalSummary.totalFrontPay) + Number(value.front_pay),
            totalOta : Number(tempTotalSummary.totalOta) + Number(value.ota_pay),
          };

          return value;
        });

        setTotalSummary(tempTotalSummary);
        setCheckInList(saleArray);

      // {
      //     "account_pay": 0,
      //     "card_pay": 70000,
      //     "cash_pay": 0,
      //     "car_no": "",
      //     "check_in_time": 1730780761061,
      //     "check_out_time": 1730854800000,
      //     "is_kiosk_check_in": false,
      //     "ota_pay": 0,
      //     "person_count": 0,
      //     "phone": "",
      //     "room_fee": 70000,
      //     "room_name": "305",
      //     "room_type_name": "스탠다드",
      //     "stay_type": "숙박:사용중"
      // }

        let room_name_filter = [
          ...new Set(saleArray.map((item) => item.room_name)),
        ].map((room_name) => ({
          value: room_name,
          text: room_name,
        }));

        let room_type_name_filter = [
          ...new Set(saleArray.map((item) => item.room_type_name)),
        ].map((room_type_name) => ({
          value: room_type_name,
          text: room_type_name,
        }));

        let stay_type_filter = [
          ...new Set(saleArray.map((item) => item.stay_type)),
        ].map((stay_type) => ({
          value: stay_type,
          text: stay_type,
        }));

        let check_in_time_filter = [
          ...new Set(saleArray.map((item) => item.check_in_time)),
        ].map((check_in_time) => ({
          value: check_in_time,
          text: check_in_time,
        }));

        let check_out_time_filter = [
          ...new Set(saleArray.map((item) => item.check_out_time)),
        ].map((check_out_time) => ({
          value: check_out_time,
          text: check_out_time,
        }));

        let is_kiosk_check_in_filter = [
          ...new Set(saleArray.map((item) => item.is_kiosk_check_in)),
        ].map((is_kiosk_check_in) => ({
          value: is_kiosk_check_in,
          text: is_kiosk_check_in,
        }));

        let phone_filter = [
          ...new Set(saleArray.map((item) => item.phone)),
        ].map((phone) => ({
          value: phone,
          text: phone,
        }));

        let car_no_filter = [
          ...new Set(saleArray.map((item) => item.car_no)),
        ].map((car_no) => ({
          value: car_no,
          text: car_no,
        }));

        let person_count_filter = [
          ...new Set(saleArray.map((item) => item.person_count)),
        ].map((person_count) => ({
          value: person_count,
          text: person_count,
        }));

        let room_fee_filter = [...new Set(saleArray.map((item) => item.room_fee))].map(
          (room_fee) => ({
            value: room_fee,
            text: room_fee,
          })
        );

        let front_pay_filter = [...new Set(saleArray.map((item) => item.front_pay))].map(
          (front_pay) => ({
            value: front_pay,
            text: front_pay,
          })
        );

        let ota_pay_filter = [...new Set(saleArray.map((item) => item.ota_pay))].map(
          (ota_pay) => ({
            value: ota_pay,
            text: ota_pay,
          })
        );

        setColumns([
          {
            title: <div className="text-c">객실명</div>,
            dataIndex: "room_name",
            key: "room_name",
            filterSearch: true,
            filters: room_name_filter,
            filteredValue: filteredInfo.room_name || null,
            onFilter: (value, record) =>
              record.room_name.toString() == value.toString(),
            sorter: (a, b) => a.room_name.localeCompare(b.room_name),
            sortOrder:
              sortedInfo.columnKey == "room_name" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">유형명</div>,
            dataIndex: "room_type_name",
            key: "room_type_name",
            filterSearch: true,
            filters: room_type_name_filter,
            filteredValue: filteredInfo.room_type_name || null,
            onFilter: (value, record) => record.room_type_name == value,
            sorter: (a, b) => a.room_type_name.localeCompare(b.room_type_name),
            sortOrder:
              sortedInfo.columnKey == "room_type_name" ? sortedInfo.order : null,
            ellipsis: true,
            responsive: ["sm"],
          },
          {
            title: <div className="text-c">입실유형</div>,
            dataIndex: "stay_type",
            key: "stay_type",
            filterSearch: true,
            filters: stay_type_filter,
            filteredValue: filteredInfo.stay_type || null,
            onFilter: (value, record) => record.stay_type == value,
            sorter: (a, b) => a.stay_type.localeCompare(b.stay_type),
            sortOrder:
              sortedInfo.columnKey == "stay_type" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">입실시간</div>,
            dataIndex: "check_in_time",
            key: "check_in_time",
            filterSearch: true,
            filters: check_in_time_filter,
            filteredValue: filteredInfo.check_in_time || null,
            onFilter: (value, record) => record.check_in_time == value,
            sorter: (a, b) => a.check_in_time.localeCompare(b.check_in_time),
            sortOrder:
              sortedInfo.columnKey == "check_in_time" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">퇴실(예정)시간</div>,
            dataIndex: "check_out_time",
            key: "check_out_time",
            filterSearch: true,
            filters: check_out_time_filter,
            filteredValue: filteredInfo.check_out_time || null,
            onFilter: (value, record) => record.check_out_time == value,
            sorter: (a, b) =>
              a.check_out_time.localeCompare(b.check_out_time),
            sortOrder:
              sortedInfo.columnKey == "check_out_time"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">무인판매</div>,
            dataIndex: "is_kiosk_check_in",
            key: "is_kiosk_check_in",
            filterSearch: true,
            filters: is_kiosk_check_in_filter,
            filteredValue: filteredInfo.is_kiosk_check_in || null,
            onFilter: (value, record) => record.is_kiosk_check_in == value,
            sorter: (a, b) => Number(a.is_kiosk_check_in) < Number(b.is_kiosk_check_in),
            sortOrder:
              sortedInfo.columnKey == "is_kiosk_check_in" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">전화번호</div>,
            dataIndex: "phone",
            key: "phone",
            filterSearch: true,
            filters: phone_filter,
            filteredValue: filteredInfo.phone || null,
            onFilter: (value, record) => record.phone == value,
            sorter: (a, b) => Number(a.phone) < Number(b.phone),
            sortOrder:
              sortedInfo.columnKey == "phone" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">차량번호</div>,
            dataIndex: "car_no",
            key: "car_no",
            filterSearch: true,
            filters: car_no_filter,
            filteredValue: filteredInfo.car_no || null,
            onFilter: (value, record) => record.car_no == value,
            sorter: (a, b) => Number(a.car_no) < Number(b.car_no),
            sortOrder:
              sortedInfo.columnKey == "car_no" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">인원수</div>,
            dataIndex: "person_count",
            key: "person_count",
            filterSearch: true,
            filters: person_count_filter,
            filteredValue: filteredInfo.person_count || null,
            onFilter: (value, record) => record.person_count == value,
            sorter: (a, b) => Number(a.person_count) < Number(b.person_count),
            sortOrder:
              sortedInfo.columnKey == "person_count" ? sortedInfo.order : null,
            render: (text) => formatNumber(text) || 0,
            ellipsis: true,
            align: "right",
          },
          {
            title: <div className="text-c">요금</div>,
            dataIndex: "room_fee",
            key: "room_fee",
            filterSearch: true,
            filters: room_fee_filter,
            filteredValue: filteredInfo.room_fee || null,
            onFilter: (value, record) => record.room_fee == value,
            sorter: (a, b) => Number(a.room_fee) < Number(b.room_fee),
            sortOrder: sortedInfo.columnKey == "room_fee" ? sortedInfo.order : null,
            render: (text) => formatNumber(text) || 0,
            ellipsis: true,
            align: "right",
          },
          {
            title: "현장결제",
            dataIndex: "front_pay",
            key: "front_pay",
            filterSearch: true,
            filters: front_pay_filter,
            filteredValue: filteredInfo.front_pay || null,
            onFilter: (value, record) => record.front_pay == value,
            sorter: (a, b) => Number(a.front_pay) < Number(b.front_pay),
            render: (text) => formatNumber(text) || 0,
            sortOrder:
              sortedInfo.columnKey == "front_pay"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "right",
          },
          {
            title: "OTA결제",
            dataIndex: "ota_pay",
            key: "ota_pay",
            filterSearch: true,
            filters: ota_pay_filter,
            filteredValue: filteredInfo.ota_pay || null,
            onFilter: (value, record) => record.ota_pay == value,
            sorter: (a, b) => Number(a.ota_pay) < Number(b.ota_pay),
            render: (text) => formatNumber(text) || 0,
            sortOrder:
              sortedInfo.columnKey == "ota_pay"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "right",
          },
        ]);

        // console.log("columns");
        // console.log(columns);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  //}, [startDateTime, endDateTime, filteredInfo, sortedInfo]);
}, [startDateTime, filteredInfo, sortedInfo]);

  // 달력
  const onChangeStartDatePicker = (date, dateString) => {
    if (dateString) {
      const endDateString = dateString + " 23:59:59";
      const startDateString = dateString + " 00:00:00";
      
      setEndDateTime(dayjs(endDateString));
      setStartDateTime(dayjs(startDateString));
    }
  };

  // const onChangeStartDatePicker = (date, dateString) => {
  //   if (dateString) {
  //     dateString += " 00:00:00"
  //     console.log(dateString);
  //     setStartDateTime(dayjs(dateString));
  //   }
  // };

  // const onChangeEndDatePicker = (date, dateString) => {
  //   if (dateString) {
  //     dateString += " 23:59:59"
  //     console.log(dateString);
  //     setEndDateTime(dayjs(dateString));
  //   }
  // };

  // const handleDownload = () => {
  //   // 데이터 시트 생성
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //   // 엑셀 파일 생성 및 다운로드
  //   XLSX.writeFile(workbook, 'data.xlsx');
  // };

  const excelDownload = () => {
    if(checkInList.length > 0){
      const workbook = XLSX.utils.book_new();
      //const worksheet = XLSX.utils.table_to_sheet(document.getElementById("mainTable"));

      let headerOrder = [
        "객실명",
        "유형명",
        "입실유형",
        "입실시간",
        "퇴실(예정)시간",
        "무인판매",
        "전화번호",
        "차량번호",
        "인원수",
        "요금",
        "현장결제",
        "OTA결제"
      ];

      let ExcelDataList = checkInList.map(item => ({
        "객실명" : item.room_name,
        "유형명" : item.room_type_name,
        "입실유형" : item.stay_type,
        "입실시간" : item.check_in_time,
        "퇴실(예정)시간" : item.check_out_time,
        "무인판매" : item.is_kiosk_check_in,
        "전화번호" : item.phone,
        "차량번호" : item.car_no,
        "인원수" : item.person_count,
        "요금" : item.room_fee,
        "현장결제" : item.front_pay,
        "OTA결제" : item.ota_pay
      }));

      ExcelDataList.push({
        "객실명" : "총 합계",
        "유형명" : totalSummary.totalCount + "건",
        "입실유형" : "",
        "입실시간" : "",
        "퇴실(예정)시간" : "",
        "무인판매" : "",
        "전화번호" : "",
        "차량번호" : "",
        "인원수" : totalSummary.totalPersonCount,
        "요금" : totalSummary.totalRoomFee,
        "현장결제" : totalSummary.totalFrontPay,
        "OTA결제" : totalSummary.totalOta
      });

      const worksheet = XLSX.utils.json_to_sheet(ExcelDataList, { header: headerOrder });

      const page_title = document.getElementById("page_title");
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, page_title.innerText + dayjs().format("_YYYYMMDD_HHmmss'") + ".xlsx");
    }
  };

  // 테이블 데이터 예시
  // const data = [
  //   {
  //     key: "1",
  //     name: "204",
  //     roomName: "일반실1",
  //     stayType: "대실",
  //     paymentDate: "2024-10-01 15:37",
  //   },
  //   {
  //     key: "2",
  //     name: "502",
  //     roomName: "일반실2",
  //     stayType: "숙박",
  //     paymentDate: "2024-10-01 11:20",
  //   },
  //   {
  //     key: "3",
  //     name: "307",
  //     roomName: "파티룸",
  //     stayType: "대실",
  //     paymentDate: "2024-09-20 01:22",
  //   },
  //   {
  //     key: "4",
  //     name: "306",
  //     roomName: "VIP",
  //     stayType: "숙박",
  //     paymentDate: "2024-08-26 16:10",
  //   },
  //   {
  //     key: "5",
  //     name: "502",
  //     roomName: "일반실2",
  //     stayType: "장기",
  //     paymentDate: "2024-10-07 19:51",
  //   },
  // ];

  // 객실명 필터
  //let filteredRoomId = [];
  // let filteredName = [...new Set(data.map((item) => item.name))].map(
  //   (name) => ({
  //     text: name,
  //     value: name,
  //   })
  // );

  // 유형명 필터
  //let filteredRoomTypeId = [];
  // let filteredRoomTypeId = [...new Set(data.map((item) => item.roomName))].map(
  //   (roomName) => ({
  //     text: roomName,
  //     value: roomName,
  //   })
  // );
  // 입실유형 필터
  //let filteredStayType = [];
  // let filteredStayType = [...new Set(data.map((item) => item.stayType))].map(
  //   (stayType) => ({
  //     text: stayType,
  //     value: stayType,
  //   })
  // );
  // 결제일자 필터
  // filteredPaymentDate = [];
  // let filteredPaymentDate = [
  //   ...new Set(
  //     data.map((item) => dayjs(item.paymentDate).format("YYYY-MM-DD"))
  //   ),
  // ].map((paymentDate) => ({
  //   text: paymentDate,
  //   value: paymentDate,
  // }));

  // 테이블 컬럼 생성

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };
  // 필터 초기화 버튼
  const clearFilters = () => {
    setFilteredInfo({});
  };
  // 모두 초기화 버튼
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setPagination({ current: 1, pageSize: 15 });
  };
  // 날짜별 정렬 버튼
  const setPaymentDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "payment_date",
    });
  };

  return (
    <div className="p-16 pb-0">
      <h2 id="page_title">입실 내역 조회</h2>

      <ConfigProvider locale={koKR}>
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          {/* <DatePicker
            showTime
            onChange={onChangeDatePicker}
            defaultValue={dayjs()}
            format="YYYY-MM-DD HH:mm:ss"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          /> */}

          <DatePicker
            //showTime
            onChange={onChangeStartDatePicker}
            defaultValue={dayjs(startDateTime)}
            format="YYYY-MM-DD"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            
          />
          {/* <span> ~ </span>
          <DatePicker
            //showTime
            onChange={onChangeEndDatePicker}
            defaultValue={dayjs(endDateTime)}
            format="YYYY-MM-DD"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
          /> */}

          <div className="btn-group ml-auto">
            <Button onClick={setPaymentDate}>날짜별 정렬</Button>
            <Button onClick={clearFilters}>전체 필터초기화</Button>
            <Button onClick={clearAll}>모두 초기화</Button>
            <Button onClick={excelDownload}>엑셀다운</Button>
          </div>
        </div>

        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={checkInList}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            // scroll={{ y: "28.8rem", x: "2600px" }}
            scroll={{ y: "27.8rem", x: "max-content" }}
            className="ant-table-respons"
            id={"mainTable"}
            summary={(pageData) => {
              // "요금" : item.room_fee,
              // "현장결제" : item.front_pay,
              // "OTA결제" : item.card_pay

              let pageCount = 0;
              let pagePersonCount = 0;
              let pageRoomFee = 0;
              let pageFrontPay = 0;
              let pageOta = 0;
              
              pageData.forEach(({person_count, room_fee, front_pay, ota_pay}) => {
                pageCount++;
                pagePersonCount += person_count; 
                pageRoomFee += room_fee;
                pageFrontPay += front_pay;
                pageOta += ota_pay;
              });
              
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>페이지 합계</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={7}>{formatNumber(pageCount) || 0}건</Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(pagePersonCount) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(pageRoomFee) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(pageFrontPay) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}><div className="text-r">{formatNumber(pageOta) || 0}</div></Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>총 합계</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={7}>{formatNumber(totalSummary.totalCount) || 0}건</Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(totalSummary.totalPersonCount) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(totalSummary.totalRoomFee) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(totalSummary.totalFrontPay) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}><div className="text-r">{formatNumber(totalSummary.totalOta) || 0}</div></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </ConfigProvider>
    </div>
  );
};
