import React, { useEffect, useState, useRef } from "react";
import { roomReserveAllByAccomSearchApi, roomReserveSelectListApi } from "../api/api";
import dayjs from "dayjs";
import {
  Input,
  Select,
  ConfigProvider,
  DatePicker,
  Table,
  Button,
  Modal,
} from "antd";
import { AlertOutlined } from "@ant-design/icons";
import koKR from "antd/lib/locale/ko_KR";
import { disabledDate, disabledTime, formatNumber } from "../util";
import { useCode } from "../login/CodeContext";
import { ReserveRegistrationModal } from "./ReserveRegistrationModal";

export const ReserveMain = () => {
  const curDateStr = dayjs().format("YYYY-MM-DD");
  const [startAt, setStartAt] = useState(dayjs(curDateStr + " 23:59:59"));
  const [state, setState] = useState("GENERATED");
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [reserveNo, setReserveNo] = useState("");
  //const [filteredData, setFilteredData] = useState(reveAllList);
  const [reveAllList, setReveAllList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  // 공통 코드 불러오는 부분
  const { coderead } = useCode();
  const code = coderead();
  const codeRoom = Object.entries(code.room);
  const codeRoomList = codeRoom.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });
  const codeState = Object.entries(code.state);
  const codeStateList = codeState.map(([key, value]) => {
    return {
      key: key,
      value: key,
      label: value,
    };
  });
  const codeStayType = Object.entries(code.stayType);
  const codeStayTypeList = codeStayType.map(([key, value]) => {
    return {
      key: key,
      value: value,
    };
  });
  const codeAgentType = Object.entries(code.agentType);
  const codeAgentTypeList = codeAgentType.map(([key, value]) => {
    return {
      key: key,
      value: value,
    };
  });
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setRefresh(false);
        const res = await roomReserveSelectListApi({startAt: dayjs(startAt).valueOf(), limit: 100, state: state});
        const resArray = Object.entries(res.room_reserves);
        const reveAllList = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            agent: value.agent,
            reserve_no: value.reserve_no,
            internal_reserve_no: value.internal_reserve_no,
            stay_type: value.stay_type,
            state: value.state,
            check_in_sched: dayjs(value.check_in_sched).format("YYYY-MM-DD HH:mm:ss"),
            check_out_sched: dayjs(value.check_out_sched).format("YYYY-MM-DD HH:mm:ss"),
            phone: value.phone,
            room_type_id: value.room_type_id,
            room_id: value.room_id,
            ota_room_name: value.ota_room_name,
            name: value.name,
            fee:  formatNumber(value.fee),
            prepaid: value.prepaid,
          };
        });

        setReveAllList(reveAllList);
        //setFilteredData(reveAllList);

        let agent_filter = [
          ...new Set(reveAllList.map((item) => item.agent)),
        ].map((agent) => ({
          value: agent,
          text: codeAgentTypeList?.find((code) => code.key === agent)?.value || "",
        }));

        let reserve_no_filter = [
          ...new Set(reveAllList.map((item) => item.reserve_no)),
        ].map((reserve_no) => ({
          value: reserve_no,
          text: reserve_no,
        }));

        let internal_reserve_no_filter = [
          ...new Set(reveAllList.map((item) => item.internal_reserve_no)),
        ].map((internal_reserve_no) => ({
          value: internal_reserve_no,
          text: internal_reserve_no,
        }));

        let stay_type_filter = [
          ...new Set(reveAllList.map((item) => item.stay_type)),
        ].map((stay_type) => ({
          value: stay_type,
          text: codeStayTypeList?.find((state) => state.key === stay_type)?.value || "",
        }));

        let state_filter = [
          ...new Set(reveAllList.map((item) => item.state)),
        ].map((state) => ({
          value: state,
          text: codeStateList?.find((code) => code.value === state)?.label || "",
        }));

        // 결제일자 필터
        let filteredCheckinDate = [
          ...new Set(reveAllList.map((item) => item.check_in_sched)),
        ].map((check_in_sched) => ({
          text: check_in_sched,
          value: check_in_sched,
        }));
        
        let check_out_sched_filter = [
          ...new Set(reveAllList.map((item) => item.check_out_sched)),
        ].map((check_out_sched) => ({
          value: check_out_sched,
          text: check_out_sched,
        }));

        let phone_filter = [
          ...new Set(reveAllList.map((item) => item.phone)),
        ].map((phone) => ({
          value: phone,
          text: phone,
        }));

        let room_id_filter = [
          ...new Set(reveAllList.map((item) => item.room_id)),
        ].map((room_id) => ({
          value: room_id,
          text: codeRoomList?.find((room) => room.key === room_id)?.value || "미배정",
        }));
        
        let room_type_id_filter = [
          ...new Set(reveAllList.map((item) => item.room_type_id)),
        ].map((room_type_id) => ({
          value: room_type_id,
          text: codeRoomTypeList?.find((roomType) => roomType.key === room_type_id)?.value || "",
        }));

        let ota_room_name_filter = [
          ...new Set(reveAllList.map((item) => item.ota_room_name)),
        ].map((ota_room_name) => ({
          value: ota_room_name,
          text: ota_room_name,
        }));

        let name_filter = [
          ...new Set(reveAllList.map((item) => item.name)),
        ].map((name) => ({
          value: name,
          text: name,
        }));

        let fee_filter = [
          ...new Set(reveAllList.map((item) => item.fee)),
        ].map((fee) => ({
          value: fee,
          text: formatNumber(fee),
        }));

        // 테이블 컬럼 생성
        setColumns([
          {
            title: <div className="text-c">채널</div>,
            dataIndex: "agent",
            key: "agent",
            width: "5%",
            align: "left",
            render: (text) => {
              return (
                codeAgentTypeList?.find((code) => code.key === text)?.value || ""
              );
            },
            filterSearch: true,
            filters: agent_filter,
            filteredValue: filteredInfo.agent || null,
            onFilter: (value, record) => record.agent == value,
            sorter: (a, b) => a.agent.localeCompare(b.agent),
            sortOrder: sortedInfo.columnKey === "agent" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">OTA예약번호</div>,
            dataIndex: "reserve_no",
            key: "reserve_no",
            width: "7%",
            align: "left",
            filterSearch: true,
            filters: reserve_no_filter,
            filteredValue: filteredInfo.reserve_no || null,
            onFilter: (value, record) => record.reserve_no == value,
            sorter: (a, b) => a.reserve_no.localeCompare(b.reserve_no),
            sortOrder: sortedInfo.columnKey === "reserve_no" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">예약번호</div>,
            dataIndex: "internal_reserve_no",
            key: "internal_reserve_no",
            width: "4%",
            align: "left",
            filterSearch: true,
            filters: internal_reserve_no_filter,
            filteredValue: filteredInfo.internal_reserve_no || null,
            onFilter: (value, record) => record.internal_reserve_no == value,
            sorter: (a, b) => a.internal_reserve_no.localeCompare(b.internal_reserve_no),
            sortOrder: sortedInfo.columnKey === "internal_reserve_no" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "입실유형",
            dataIndex: "stay_type",
            key: "stay_type",
            width: "4%",
            align: "center",
            render: (text, record, index) => {
              return (
                codeStayTypeList?.find((state) => state.key === text)?.value || ""
              );
            },
            filterSearch: true,
            filters: stay_type_filter,
            filteredValue: filteredInfo.stay_type || null,
            onFilter: (value, record) => record.stay_type == value,
            sorter: (a, b) => a.stay_type.localeCompare(b.stay_type),
            sortOrder: sortedInfo.columnKey === "stay_type" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "상태",
            dataIndex: "state",
            key: "state",
            width: "4%",
            align: "center",
            render: (text, record, index) => {
              return codeStateList?.find((code) => code.value === text)?.label || "";
            },
            filterSearch: true,
            filters: state_filter,
            filteredValue: filteredInfo.state || null,
            onFilter: (value, record) => record.state == value,
            sorter: (a, b) => a.state.localeCompare(b.state),
            sortOrder: sortedInfo.columnKey === "state" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "입실시간",
            dataIndex: "check_in_sched",
            key: "check_in_sched",
            width: "7%",
            align: "center",
            render: (text, record, index) => {
              return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
            },

            filterSearch: true,
            filters: filteredCheckinDate,
            filteredValue: filteredInfo.check_in_sched || null,
            onFilter: (value, record) =>
              dayjs(record.check_in_sched).format("YYYY-MM-DD") === value,
            sorter: (a, b) => a.check_in_sched.localeCompare(b.check_in_sched),
            sortOrder:
              sortedInfo.columnKey === "check_in_sched" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "퇴실시간",
            dataIndex: "check_out_sched",
            key: "check_out_sched",
            width: "7%",
            align: "center",
            render: (text, record, index) => {
              return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
            },
            filterSearch: true,
            filters: check_out_sched_filter,
            filteredValue: filteredInfo.check_out_sched || null,
            onFilter: (value, record) => record.check_out_sched == value,
            sorter: (a, b) => a.check_out_sched.localeCompare(b.check_out_sched),
            sortOrder: sortedInfo.columnKey === "check_out_sched" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">휴대폰</div>,
            dataIndex: "phone",
            key: "phone",
            width: "5%",
            align: "left",
            filterSearch: true,
            filters: phone_filter,
            filteredValue: filteredInfo.phone || null,
            onFilter: (value, record) => record.phone == value,
            sorter: (a, b) => a.phone.localeCompare(b.phone),
            sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "객실명",
            dataIndex: "room_id",
            key: "room_id",
            width: "5%",
            align: "center",
            render: (text, record, index) => {
              return (
                codeRoomList?.find((room) => room.key === text)?.value || "미배정"
              );
            },
            filterSearch: true,
            filters: room_id_filter,
            filteredValue: filteredInfo.room_id || null,
            onFilter: (value, record) => record.room_id == value,
            sorter: (a, b) => (a.room_id || "미배정").localeCompare(b.room_id || "미배정"),
            sortOrder: sortedInfo.columnKey === "room_id" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "객실유형",
            dataIndex: "room_type_id",
            key: "room_type_id",
            width: "5%",
            align: "center",
            render: (text, record, index) => {
              return (
                codeRoomTypeList?.find((roomType) => roomType.key === text)?.value || ""
              );
            },
            filterSearch: true,
            filters: room_type_id_filter,
            filteredValue: filteredInfo.room_type_id || null,
            onFilter: (value, record) => record.room_type_id == value,
            sorter: (a, b) => (a.room_type_id || "").localeCompare(b.room_type_id || ""),
            sortOrder: sortedInfo.columnKey === "room_type_id" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">상품유형(OTA)</div>,
            dataIndex: "ota_room_name",
            key: "ota_room_name",
            width: "10%",
            align: "left",
            filterSearch: true,
            filters: ota_room_name_filter,
            filteredValue: filteredInfo.ota_room_name || null,
            onFilter: (value, record) => record.ota_room_name == value,
            sorter: (a, b) => a.ota_room_name.localeCompare(b.ota_room_name),
            sortOrder: sortedInfo.columnKey === "ota_room_name" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "예약자",
            dataIndex: "name",
            key: "name",
            width: "5%",
            align: "center",
            filterSearch: true,
            filters: name_filter,
            filteredValue: filteredInfo.name || null,
            onFilter: (value, record) => record.name == value,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">요금</div>,
            dataIndex: "fee",
            key: "fee",
            width: "5%",
            align: "right",
            filterSearch: true,
            filters: fee_filter,
            filteredValue: filteredInfo.fee || null,
            onFilter: (value, record) => record.fee == value,
            sorter: (a, b) => a.fee.localeCompare(b.fee),
            sortOrder: sortedInfo.columnKey === "fee" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: "3%",
            render: (record) => <Button onClick={() => showModal(record.key)}>편집</Button>,
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [refresh, startAt, state, filteredInfo, sortedInfo]);

  // 달력
  const onChangeDatePicker = (date, dateString) => {
    setStartAt(dayjs(dateString+" 23:59:59").valueOf());
    
    const filtered = reveAllList.filter(
      (item) =>
        dayjs(item.check_in_sched).format("YYYY-MM-DD") ===
        dayjs(dateString).format("YYYY-MM-DD")
    );
    setReveAllList(filtered);
    //setFilteredData(filtered);
  };

  // 날짜별 정렬 버튼
  const setCheckinDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "check_in_sched",
    });
  };

  const handleSelectChange = (value) => {
    setState(value);
    const filtered = reveAllList.filter((item) => item.state === value);
    setReveAllList(filtered);
    //setFilteredData(filtered);
  };

  // 검색 input(입력폼)
  const handleSearch = (value) => {
    const filtered = reveAllList.filter(
      (item) =>
        item.key.includes(value) ||
        item.internal_reserve_no.includes(value) ||
        item.name.includes(value)
    );
    setReveAllList(filtered);
    //setFilteredData(filtered);
    setSearchText(value);
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  // 모달
  const childComponentRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (no) => {
    setReserveNo(no);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setRefresh(true);
  };
  const handleOk = () => {
    Modal.confirm({
      title: "저장하시겠습니까?",
      width: "440px",
      icon: ( <AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} /> ),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk() {
        if (childComponentRef.current !== undefined) {
          childComponentRef.current.usedInParentComponent();
        }
        setRefresh(true);
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div className="p-16 pb-0">
        <h2>예약 관리</h2>

        <ConfigProvider locale={koKR}>
          <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
            <DatePicker
              showTime
              onChange={onChangeDatePicker}
              defaultValue={startAt}
              format="YYYY-MM-DD"
              style={{ minWidth: "8.75rem" }}
              placement={"bottomLeft"}
              //disabledDate={disabledDate}
              //disabledTime={disabledTime}
            />
            {/**
        <div className="btn-group ml-auto">
          <Button onClick={setPaymentDate}>날짜별 정렬</Button>
          <Button onClick={clearFilters}>전체 필터초기화</Button>
          <Button onClick={clearAll}>모두 초기화</Button>
        </div>
          */}
            <Select
              placeholder="예약상태"
              options={codeStateList}
              defaultValue={"GENERATED"}
              style={{
                width: "100%",
                minWidth: "100px",
                maxWidth: "160px",
                textAlign: "left",
              }}
              onChange={(key) => handleSelectChange(key)}
            ></Select>

            <div className="flex-row flex-wrap gap-8 ml-auto">
              <Input
                placeholder="예약번호 또는 예약자명으로 검색하세요"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: "18.75rem" }}
              />
              <Button type="primary" htmlType="button" onClick={() => showModal("")}>
                예약등록
              </Button>
            </div>
          </div>

          <div className="table-wrap-antd">
            <Table
              columns={columns}
              dataSource={reveAllList}
              onChange={handleChange}
              pagination={pagination}
              rowKey="key"
              scroll={{
                y: "28.8rem",
                x: "2400px",
              }}
              className="ant-table-respons"
            />
          </div>
        </ConfigProvider>
      </div>

      <Modal
        title={<h2>예약 등록</h2>}
        open={isModalOpen}
        onOk={handleOk}
        okText="저장"
        onCancel={closeModal}
        cancelText="닫기"
        width={1000}
        maskClosable={false}
      >
        <ReserveRegistrationModal pReserveNo={reserveNo} ref={childComponentRef} closeModal={closeModal}/>
      </Modal>
    </>
  );
  //<h1>예약메뉴의 메인페이지 입니다.</h1>;
};
